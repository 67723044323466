/* src/styles.css */

body {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana,
      sans-serif;
    color: rgb(91, 76, 76);
  }

  a {
    display: inline-block; 
    transition: transform 0.3s ease;
  }
  a:hover {
    transform: scale(1.1);
  }
  
  header {
    text-align: center;
    padding: 20px;
  }
  
  .master-intro-image {
    width: 50%;
    object-fit: cover;
  }
  
  .navigation-bar {
    width: 40%;
    margin: 0 auto;
  }
  
  .navigation-bar a {
    margin: 0 10%;
    text-decoration: none;
    color: #d78f8f;
    font-weight: bold;
  }
  
  .navigation-bar a:hover {
    color: #b67979;
    /* transform: scale(1.1); */
  }

  footer {
    background-color: rgb(225, 199, 199);
    color: #fff;
    text-align: center;
    padding: 20px;
    position: relative;
    bottom: 0;
    width: 100%;
  }